import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-pulse-point.css'
import { Link } from 'react-router-dom'

const PagesliderPulsePoint = (props) => {
  const { increaseTrainingPercentage } = props;

  const [show_summary, setShow_summary] = useState(false)
  const [slideNumberPP, setSlideNumberPP] = useState(1)
  const [isPlaying, setIsPlaying] = useState(true);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [linkDestination, setLinkDestination] = useState('/conclusion');

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/pulsepoint');

    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberPP]);
  
 
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
  try {
    const resp = await fetch(
      `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
    );
    if (!resp.ok) {
      // throw new Error(`HTTP error! status: ${resp.status}`);
      console.log('Fetch failed:', resp.status);

    }
  } catch (error) {
    console.log('Fetch failed:');
  }
}

const calculateTimeSpent = (slidenumorname) => {
  const timespent=Date.now() - startTime;
  console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
  adduserdata("PP"+slidenumorname+"Time",timespent)
  setStartTime(Date.now())
}

  const videoMapQ1Q2 = {
    Q1: "/external/PP1-1.mp4",
    Q2: "/external/PP1-2.mp4",
  };
  
  function handleButtonClickQ12(choice) {
    const videoSrc = videoMapQ1Q2[choice];
    const video = document.querySelector(".pageslider-pulse-point-video");
    video.src = videoSrc;
    video.play();
    setIsPlaying(true);
    console.log("PPChoice_"+choice);
    adduserdata("PPChoice",choice);
    calculateTimeSpent('Q1Q2');

  }

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handleButtonClick = (newSlideNumber) => {
    calculateTimeSpent(slideNumberPP);
    setIsPlaying(true);
    setSlideNumberPP(newSlideNumber);
    setVideoEnded(false);
  }
  const videoMapYNMay = {
    1: "/external/PP6-1.mp4",//yes
    2: "/external/PP6-23.mp4",//no
    3: "/external/PP6-23.mp4",//maybe
  };
  function handleButtonClickYNMaybe(response) {
    const videoSrc = videoMapYNMay[response];
    const video = document.querySelector(".pageslider-pulse-point-video");
    video.src = videoSrc;
    video.play();
    console.log("Watch_Stigma_"+response);
    adduserdata("Watch_Stigma",response);
    if (response === '1') {
      setLinkDestination(`/stigma?username=${original_username}`);
    } else if (response === '2') {
      setLinkDestination(`/conclusion?username=${original_username}`);
    } else if (response === '3') {
      setLinkDestination(`/conclusion?username=${original_username}`);
    }
  }


  return (
    <div className={`pageslider-pulse-point-container ${props.rootClassName} `}>
      {slideNumberPP === 1 && (
        <div className="pageslider-pulse-point-p1">
          <div className="pageslider-pulse-point-container01">
            <video
              ref={videoRef}
              src="/external/PP1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              className="pageslider-pulse-point-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded && (
            <div className="pageslider-pulse-point-summary">
              <span className="pageslider-pulse-point-text">
                Click on the question that is most important to you.
              </span>
              <ul className="pageslider-pulse-point-ul list">
                <li className="pageslider-pulse-point-li button list-item">
                  <div className="pageslider-pulse-point-container02">
                    <span className="pageslider-pulse-point-text01" onClick={() => {handleButtonClickQ12('Q1');document.querySelector('.pageslider-pulse-point-summary').remove()}}>
                      How will I know if someone needs my help?
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li01 button list-item">
                  <div className="pageslider-pulse-point-container03">
                    <span className="pageslider-pulse-point-text02">
                      <span className="pageslider-pulse-point-text03" onClick={() => {handleButtonClickQ12('Q2');document.querySelector('.pageslider-pulse-point-summary').remove()}}>
                        If I help, will everyone know my name?
                      </span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(2)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberPP === 2 && (
        <div className="pageslider-pulse-point-p2">
          <div className="pageslider-pulse-point-container04">
            <video
              ref={videoRef}
              src="/external/PP2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-pulse-point-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-pulse-point-summary1">
              <span className="pageslider-pulse-point-text05">
                PulsePoint
              </span>
              <ul className="pageslider-pulse-point-ul1 list">
                <li className="pageslider-pulse-point-li02 list-item">
                  <div className="pageslider-pulse-point-container05">
                    <span className="pageslider-pulse-point-text06">
                      Connected to local 911
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li03 list-item">
                  <div className="pageslider-pulse-point-container06">
                    <span className="pageslider-pulse-point-text07">
                      Informs nearby emergencies in real-time
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li04 list-item">
                  <div className="pageslider-pulse-point-container07">
                    <span className="pageslider-pulse-point-text08">
                      Requests help when CPR is needed nearby
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li05 list-item">
                  <div className="pageslider-pulse-point-container08">
                    <span className="pageslider-pulse-point-text09">
                      Only available in areas where local public safety agencies
                      have adopted PulsePoint.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(3)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberPP === 3 && (
        <div className="pageslider-pulse-point-p3">
          <div className="pageslider-pulse-point-container09">
            <video
              ref={videoRef}
              src="/external/PP3.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-pulse-point-summarybutton2 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-pulse-point-summary2">
              <span className="pageslider-pulse-point-text10">
                How to setup Pulsepoint?
              </span>
              <ul className="pageslider-pulse-point-ul2 list">
                <li className="pageslider-pulse-point-li06 list-item">
                  <div className="pageslider-pulse-point-container10">
                    <span className="pageslider-pulse-point-text11">
                      Download on Android/Apple phones
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li07 list-item">
                  <div className="pageslider-pulse-point-container11">
                    <span className="pageslider-pulse-point-text12">
                      Open app and select Get Started.
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li08 list-item">
                  <div className="pageslider-pulse-point-container12">
                    <span className="pageslider-pulse-point-text13">
                      Review the terms of service and agree to continue.
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li09 list-item">
                  <div className="pageslider-pulse-point-container13">
                    <span className="pageslider-pulse-point-text14">
                      Allow PulsePoint to send you notifications
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(4)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberPP === 4 && (
        <div className="pageslider-pulse-point-p4">
          <div className="pageslider-pulse-point-container14">
            <video
              ref={videoRef}
              src="/external/PP4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-pulse-point-summarybutton3 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-pulse-point-summary3">
              <span className="pageslider-pulse-point-text15">
                How to setup PulsePoint?
              </span>
              <ul className="pageslider-pulse-point-ul3 list">
                <li className="pageslider-pulse-point-li10 list-item">
                  <div className="pageslider-pulse-point-container15">
                    <span className="pageslider-pulse-point-text16">
                      Download on Android/Apple phones
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li11 list-item">
                  <div className="pageslider-pulse-point-container16">
                    <span className="pageslider-pulse-point-text17">
                      Open app and select Get Started.
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li12 list-item">
                  <div className="pageslider-pulse-point-container17">
                    <span className="pageslider-pulse-point-text18">
                      Review the terms of service and agree to continue.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li13 list-item">
                  <div className="pageslider-pulse-point-container18">
                    <span className="pageslider-pulse-point-text19">
                      Allow PulsePoint to send you notifications
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li14 list-item">
                  <div className="pageslider-pulse-point-container19">
                    <span className="pageslider-pulse-point-text20">
                      Enable CPR Alerts
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li15 list-item">
                  <div className="pageslider-pulse-point-container20">
                    <span className="pageslider-pulse-point-text21">
                      Click Continue on the remaining prompts
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li16 list-item">
                  <div className="pageslider-pulse-point-container21">
                    <span className="pageslider-pulse-point-text22">
                      Click Finish to complete first time prompts
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(5)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberPP === 5 && (
        <div className="pageslider-pulse-point-p5">
          <div className="pageslider-pulse-point-container22">
            <video
              ref={videoRef}
              src="/external/PP5.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-pulse-point-summarybutton4 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-pulse-point-summary4">
              <span className="pageslider-pulse-point-text23">
                How to select your agency?
              </span>
              <ul className="pageslider-pulse-point-ul4 list">
                <li className="pageslider-pulse-point-li17 list-item">
                  <div className="pageslider-pulse-point-container23">
                    <span className="pageslider-pulse-point-text24">
                      Press the second icon at the bottom
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li18 list-item">
                  <div className="pageslider-pulse-point-container24">
                    <span className="pageslider-pulse-point-text25">
                      Select Find Agency To Follow.
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li19 list-item">
                  <div className="pageslider-pulse-point-container25">
                    <span className="pageslider-pulse-point-text26">
                      Search by agency name/city served/zipcode/GPS
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li20 list-item">
                  <div className="pageslider-pulse-point-container26">
                    <span className="pageslider-pulse-point-text27">
                      Press the Follow button on desired agency
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(6)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberPP === 6 && (
        <div className="pageslider-pulse-point-p6">
          <div className="pageslider-pulse-point-container27">
            <video
              ref={videoRef}
              src="/external/PP6.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-pulse-point-summarybutton5 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-pulse-point-summary5">
              <span className="pageslider-pulse-point-text28">
                How to use PulsePoint to respond to emergency?
              </span>
              <ul className="pageslider-pulse-point-ul5 list">
                <li className="pageslider-pulse-point-li21 list-item">
                  <div className="pageslider-pulse-point-container28">
                    <span className="pageslider-pulse-point-text29">
                      PulsePoint will send a notification of an emergency event
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li22 list-item">
                  <div className="pageslider-pulse-point-container29">
                    <span className="pageslider-pulse-point-text30">
                      Open the notification, select I am responding, Guide me
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li23 list-item">
                  <div className="pageslider-pulse-point-container30">
                    <span className="pageslider-pulse-point-text31">
                      {' '}
                      Use the address to reach the destination.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(7)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
  {slideNumberPP === 7 && (
        <div className="pageslider-pulse-point-p1">
          <div className="pageslider-pulse-point-container01">
            <video
              ref={videoRef}
              src="/external/PP7.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-pulse-point-video video"
            ></video>
            <button
              type="button"
              className="pageslider-pulse-point-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded && (
            <div className="pageslider-pulse-point-summary">
              <span className="pageslider-pulse-point-text">
                Next, I’d like to share some information about the stereotypes that people have about people who misuse opioids. Would you like to do this part of the training now?
              </span>
              <ul className="pageslider-pulse-point-ul list">
                <li className="pageslider-pulse-point-li button list-item">
                  <div className="pageslider-pulse-point-container02">
                    <span className="pageslider-pulse-point-text01" onClick={() => {handleButtonClickYNMaybe('1');document.querySelector('.pageslider-pulse-point-summary').remove()}}>
                      Yes
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li01 button list-item">
                  <div className="pageslider-pulse-point-container03">
                    <span className="pageslider-pulse-point-text02">
                      <span className="pageslider-pulse-point-text03" onClick={() => {handleButtonClickYNMaybe('2');document.querySelector('.pageslider-pulse-point-summary').remove()}}>
                       No
                      </span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
                <li className="pageslider-pulse-point-li01 button list-item">
                  <div className="pageslider-pulse-point-container03">
                    <span className="pageslider-pulse-point-text02">
                      <span className="pageslider-pulse-point-text03" onClick={() => {handleButtonClickYNMaybe('3');document.querySelector('.pageslider-pulse-point-summary').remove()}}>
                       Maybe Later
                      </span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
          <Link to={linkDestination} className="pageslider-intro-navlink" onClick={() => {calculateTimeSpent(slideNumberPP); increaseTrainingPercentage(84);}}>
          <svg viewBox="0 0 1024 1024" className="pageslider-pulse-point-next5">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
      {/* <div className="pageslider-pulse-point-p10">
        <div className="pageslider-pulse-point-container31">
          <div className="">
            <div className="pageslider-pulse-point-container33">
              <Script
                html={`<iframe width="442" height="800" src="https://scarevrow.invisionapp.com/console/share/D5TZWMBYHEA" frameborder="0" ></iframe>`}
                className=""
              ></Script>
            </div>
          </div>
        </div>
        <svg viewBox="0 0 1024 1024" className="pageslider-pulse-point-next6">
          <path
            d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
            className=""
          ></path>
          <path
            d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
            className=""
          ></path>
        </svg>
      </div> */}
    </div>
  )
}

PagesliderPulsePoint.defaultProps = {
  rootClassName: '',
}

PagesliderPulsePoint.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderPulsePoint
