import React from 'react'

import { Helmet } from 'react-helmet'

import PagesliderPulsePoint from '../components/pageslider-pulse-point'
import './pulsepoint.css'

const Pulsepoint = (props) => {
  const { increaseTrainingPercentage } = props;
  return (
    <div className="pulsepoint-container">
      <Helmet>
        <title>Pulsepoint - ORRS</title>
        <meta property="og:title" content="Pulsepoint - ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <PagesliderPulsePoint increaseTrainingPercentage={increaseTrainingPercentage} rootClassName="pageslider-pulse-point-root-class-name"></PagesliderPulsePoint>
      <footer className="pulsepoint-footer">
        <span className="pulsepoint-text">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="pulsepoint-text2">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="pulsepoint-text4 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default Pulsepoint
