import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  const originalUsername = props.originalUsername;
  return (
    <div className="home-container">
      <Helmet>
        <title>ORRS</title>
        <meta property="og:title" content="ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <div className="home-center-frame">
        <img src="/external/pastedimage-e6s-1000w.png" className="home-image" />
        <h1 className="home-text01">Helping our communities</h1>
        <Link to={`/introduction?username=${originalUsername}`} className="home-navlink button">
          <span className="home-text02">
            <span>Start Course</span>
            <br></br>
          </span>
        </Link>
      </div>
      <footer className="home-footer">
        <span className="home-text05">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text07">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="home-text09 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default Home
