import React from 'react'

import { Helmet } from 'react-helmet'

import PagesliderHowNARCAN from '../components/pageslider-how-narcan'
import './how-narcan.css'

const HowNARCAN = (props) => {
  const { increaseTrainingPercentage } = props;

  return (
    <div className="how-narcan-container">
      <Helmet>
        <title>HowNARCAN - ORRS</title>
        <meta property="og:title" content="HowNARCAN - ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <PagesliderHowNARCAN increaseTrainingPercentage={increaseTrainingPercentage} rootClassName="pageslider-how-narcan-root-class-name"></PagesliderHowNARCAN>
      <footer className="how-narcan-footer">
        <span className="how-narcan-text2">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="how-narcan-text4">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="how-narcan-text6 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default HowNARCAN
