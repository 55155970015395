import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-conc.css'

const PagesliderConc = (props) => {
  const { increaseTrainingPercentage } = props;

  const [show_summary, setShow_summary] = useState(false)
  const [Conc, setConc] = useState(1)
  const [isPlaying, setIsPlaying] = useState(true);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/conclusion');

    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [Conc]);
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
      try {
        const resp = await fetch(
          `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
        );
        if (!resp.ok) {
          // throw new Error(`HTTP error! status: ${resp.status}`);
          console.log('Fetch failed:', resp.status);

        }
      } catch (error) {
        console.log('Fetch failed:');
      }
    }

    const calculateTimeSpent = (slidenumorname) => {
      const timespent=Date.now() - startTime;
      console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
      adduserdata("Conc"+slidenumorname+"Time",timespent)
      setStartTime(Date.now())
    }

    const handlePlayPause = () => {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);

      }
    };

    const handleReplay = () => {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsPlaying(true);
    };

    const handleButtonClick = (newSlideNumber) => {
      calculateTimeSpent(Conc);
      setIsPlaying(true);
      setConc(newSlideNumber);
      setSelectedAnswer(null);
      setVideoEnded(false);
    }

    const handleAnswerSelection = (q_num, answer, isCorrect) => {
      setSelectedAnswer(answer);
      setIsCorrect(isCorrect);

      if(q_num === 1) {
        if (isCorrect) {
          videoRef.current.src = "/external/Concl3-1.mp4";
        } else {
          videoRef.current.src = "/external/Concl3-2.mp4";
        }
      } else if (q_num === 2) {
        if (isCorrect) {
          videoRef.current.src = "/external/Concl4-1.mp4";
        } else {
          videoRef.current.src = "/external/Concl4-2.mp4";
        }
      }else if (q_num === 3) {
        if (isCorrect) {
          videoRef.current.src = "/external/Concl5-1.mp4";
        } else {
          videoRef.current.src = "/external/Concl5-2.mp4";
        }
      }else if (q_num === 4) {
        if (isCorrect) {
          videoRef.current.src = "/external/Concl6-2.mp4";
        } else {
          videoRef.current.src = "/external/Concl6-1.mp4";
        }
      }else if (q_num === 5) {
        if (isCorrect) {
          videoRef.current.src = "/external/Concl7-2.mp4";
        } else {
          videoRef.current.src = "/external/Concl7-1.mp4";
        }
      }else if (q_num === 6) {
          if (isCorrect) {
            videoRef.current.src = "/external/Concl8-2.mp4";
          } else {
            videoRef.current.src = "/external/Concl8-1.mp4";
          }
        }else if (q_num === 7) {
          if (isCorrect) {
            videoRef.current.src = "/external/Concl9-2.mp4";
          } else {
            videoRef.current.src = "/external/Concl9-1.mp4";
          }
        }

        console.log("Quiz"+q_num+"IsCorrect_"+isCorrect);
        adduserdata("Quiz"+q_num+"IsCorrect",isCorrect);
        console.log("Quiz"+q_num+"SelectedChoice_"+answer);
        adduserdata("Quiz"+q_num+"SelectedChoice",answer);

        // calculateTimeSpent('Quiz'+q_num);


      // Disable the choice buttons
      // This assumes you have a reference to the buttons and can disable them.
      // Update this with your actual logic to disable the buttons.
      // choiceButtons.forEach(button => button.disabled = true);
    };


  return (
    <div className={`pageslider-conc-container ${props.rootClassName} `}>
      {Conc === 1 && (
        <div className="pageslider-conc-p1">
          <div className="pageslider-conc-container1">
            <video
              ref={videoRef}
              src="/external/Concl00.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(2)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 2 && (
        <div className="pageslider-conc-p1">
          <div className="pageslider-conc-container1">
            <video
              ref={videoRef}
              src="/external/Concl05.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(3)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 3 && (
        <div className="pageslider-conc-p1">
          <div className="pageslider-conc-container1">
            <video
              ref={videoRef}
              src="/external/Concl1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(4)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 4 && (
        <div className="pageslider-conc-p2">
          <div className="pageslider-conc-container4">
            <video
            ref={videoRef}
              src="/external/Concl2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(5)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
          {/* <button type="button" className="pageslider-conc-button button">
            Complete Survey
          </button> */}
        </div>
      )}
      {Conc === 5 && (
        <div className="pageslider-conc-p3">
          <div className="pageslider-conc-container09">
            <video
              ref={videoRef}
              src="/external/Concl3.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton2 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary2">
              <span className="pageslider-conc-text08">
              What is the best way to check how well an unresponsive person is breathing?
              </span>
              <ul className="pageslider-conc-ul2 list">
                <li className="pageslider-conc-li06 button list-item" style={selectedAnswer === '3A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container10">
                    <button className="pageslider-conc-text09" onClick={() => handleAnswerSelection(1, '3A', false)} disabled={selectedAnswer !== null}>
                      Put your ear close to their mouth and listen for breathing sounds
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li07 button list-item" style={selectedAnswer === '3B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container11">
                    <button className="pageslider-conc-text10" onClick={() => handleAnswerSelection(1, '3B', true)} disabled={selectedAnswer !== null}>
                      Put your hand on their chest and watch how quickly it rises and falls
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li08 button list-item" style={selectedAnswer === '3C' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container12">
                    <button className="pageslider-conc-text11" onClick={() => handleAnswerSelection(1, '3C', false)} disabled={selectedAnswer !== null}>
                      Put your hand on their nose to see if their nostrils move
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li09 button list-item" style={selectedAnswer === '3D' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container13">
                    <button className="pageslider-conc-text12" onClick={() => handleAnswerSelection(1, '3D', false)} disabled={selectedAnswer !== null}>
                      None of these are appropriate ways to check for breathing.
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(6)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 6 && (
        <div className="pageslider-conc-p4">
          <div className="pageslider-conc-container14">
            <video
              ref={videoRef}
              src="/external/Concl4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton3 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary3">
              <span className="pageslider-conc-text13">
                When you arrive on the scene of a potential opioid overdose, you
                should look for an adult to assist if needed. Which of the
                following information should you NOT give this person?
              </span>
              <ul className="pageslider-conc-ul3 list">
                <li className="pageslider-conc-li10 button list-item" style={selectedAnswer === '4A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container15">
                    <button className="pageslider-conc-text14" onClick={() => handleAnswerSelection(2, '4A', false)} disabled={selectedAnswer !== null}>
                      That you are a PulsePoint volunteer.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li11 button list-item" style={selectedAnswer === '4B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container16">
                    <button className="pageslider-conc-text15" onClick={() => handleAnswerSelection(2, '4B', false)} disabled={selectedAnswer !== null}>
                      That you received an alert from the 911 call center that
                      someone needs medical help.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li12 button list-item" style={selectedAnswer === '4C' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container17">
                    <button className="pageslider-conc-text16" onClick={() => handleAnswerSelection(2, '4C', false)} disabled={selectedAnswer !== null}>
                      That you have Narcan.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li13 button list-item" style={selectedAnswer === '4D' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container18">
                    <button className="pageslider-conc-text17" onClick={() => handleAnswerSelection(2, '4D', true)} disabled={selectedAnswer !== null}>
                      Your first name, last name, and address.
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(7)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 7 && (
        <div className="pageslider-conc-p5">
          <div className="pageslider-conc-container19">
            <video
              ref={videoRef}
              src="/external/Concl5.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton4 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary4">
              <span className="pageslider-conc-text18">
                Which of the following is NOT true about NARCAN?
              </span>
              <ul className="pageslider-conc-ul4 list">
                <li className="pageslider-conc-li14 button list-item" style={selectedAnswer === '5A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container20">
                    <button className="pageslider-conc-text19" onClick={() => handleAnswerSelection(3, '5A', false)} disabled={selectedAnswer !== null}>
                      NARCAN blocks opioids from binding to the opioid receptors
                      in the brain.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li15 button list-item" style={selectedAnswer === '5B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container21">
                    <button className="pageslider-conc-text20" onClick={() => handleAnswerSelection(3, '5B', false)} disabled={selectedAnswer !== null}>
                      NARCAN reduces symptoms of overdose within 2-3 minutes
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li16 button list-item" style={selectedAnswer === '5C' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container22">
                    <button className="pageslider-conc-text21" onClick={() => handleAnswerSelection(3, '5C', false)} disabled={selectedAnswer !== null}>
                      NARCAN lasts 30-90 minutes
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li17 button list-item" style={selectedAnswer === '5D' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container23">
                    <button className="pageslider-conc-text22" onClick={() => handleAnswerSelection(3, '5D', true)} disabled={selectedAnswer !== null}>
                      NARCAN is unsafe to use unless you are 100% sure opioids
                      are involved.
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(8)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 8 && (
        <div className="pageslider-conc-p6">
          <div className="pageslider-conc-container24">
            <video
              ref={videoRef}
              src="/external/Concl6.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton5 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary5">
              <span className="pageslider-conc-text23">
                The first step in administering NARCAN is to hold the NARCAN
                nasal spray with your thumb on the bottom of the plunger and
                your first and middle fingers on either side of the nozzle.
                What&apos;s the next step?
              </span>
              <ul className="pageslider-conc-ul5 list">
                <li className="pageslider-conc-li18 button list-item" style={selectedAnswer === '6A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container25">
                    <button className="pageslider-conc-text24" onClick={() => handleAnswerSelection(4, '6A', false)} disabled={selectedAnswer !== null}>
                      Press the plunger to test that the medication comes out.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li19 button list-item" style={selectedAnswer === '6B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container26">
                    <button className="pageslider-conc-text25" onClick={() => handleAnswerSelection(4, '6B', true)} disabled={selectedAnswer !== null}>
                      Gently insert the tip of the nozzle into one nostril until
                      your fingers on either side of the nozzle are against the
                      person&apos;s nose. Press the plunger firmly to give the
                      full dose of NARCAN nasal spray.
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(9)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 9 && (
        <div className="pageslider-conc-p7">
          <div className="pageslider-conc-container27">
            <video
              ref={videoRef}
              src="/external/Concl7.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton6 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary6">
              <span className="pageslider-conc-text26">
                What is the main advantage of CPR with breaths over hands-only
                CPR?
              </span>
              <ul className="pageslider-conc-ul6 list">
                <li className="pageslider-conc-li20 button list-item" style={selectedAnswer === '7A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container28">
                    <button className="pageslider-conc-text27" onClick={() => handleAnswerSelection(5, '7A', false)} disabled={selectedAnswer !== null}>
                      Helps circulate blood in the body.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li21 button list-item" style={selectedAnswer === '7B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container29">
                    <button className="pageslider-conc-text28" onClick={() => handleAnswerSelection(5, '7B', true)} disabled={selectedAnswer !== null}>
                      Adds additional oxygen to the body.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li22 button list-item" style={selectedAnswer === '7C' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container30">
                    <button className="pageslider-conc-text29" onClick={() => handleAnswerSelection(5, '7C', false)} disabled={selectedAnswer !== null}>
                      Helpful in an opioid emergency.
                    </button>
                  </div>
                </li>
                <li className="pageslider-conc-li23 button list-item" style={selectedAnswer === '7D' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container31">
                    <button className="pageslider-conc-text30" onClick={() => handleAnswerSelection(5, '7D', false)} disabled={selectedAnswer !== null}>
                      Involves rapid chest compressions.
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(10)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 10 && (
        <div className="pageslider-conc-p8">
          <div className="pageslider-conc-container32">
            <video
              ref={videoRef}
              src="/external/Concl8.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton7 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary7">
              <span className="pageslider-conc-text31">
                PulsePoint will send volunteers a special type of alert
                for an opioid overdose. True or False?
              </span>
              <ul className="pageslider-conc-ul7 list">
                <li className="pageslider-conc-li24 button list-item" style={selectedAnswer === '8A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container33">
                    <button className="pageslider-conc-text32" onClick={() => handleAnswerSelection(6, '8A', false)} disabled={selectedAnswer !== null}> True</button>
                  </div>
                </li>
                <li className="pageslider-conc-li25 button list-item" style={selectedAnswer === '8B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container34">
                    <button className="pageslider-conc-text33" onClick={() => handleAnswerSelection(6, '8B', true)} disabled={selectedAnswer !== null}> False</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(11)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {Conc === 11 && (
        <div className="pageslider-conc-p9">
          <div className="pageslider-conc-container35">
            <video
              ref={videoRef}
              src="/external/Concl9.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
            <button
              type="button"
              className="pageslider-conc-summarybutton8 button"
            >
              Show Summary
            </button>
            <div className="pageslider-conc-summary8">
              <span className="pageslider-conc-text34">
                Telling people that you carry NARCAN could make them more likely
                to try illegal drugs. True or False?
              </span>
              <ul className="pageslider-conc-ul8 list">
                <li className="pageslider-conc-li26 button list-item" style={selectedAnswer === '9A' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container36">
                    <button className="pageslider-conc-text35" onClick={() => handleAnswerSelection(7, '9A', false)} disabled={selectedAnswer !== null}> True</button>
                  </div>
                </li>
                <li className="pageslider-conc-li27 button list-item" style={selectedAnswer === '9B' ? { backgroundColor: 'grey' } : {}}>
                  <div className="pageslider-conc-container37">
                    <button className="pageslider-conc-text36" onClick={() => handleAnswerSelection(7, '9B', true)} disabled={selectedAnswer !== null}> False</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
          <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(12)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
    {Conc === 12 && (
        <div className="pageslider-conc-p2">
          <div className="pageslider-conc-container4">
            <video
            ref={videoRef}
              src="/external/Concl10.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-conc-video video"
            ></video>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          </div>
          {videoEnded && (
          <div className="pageslider-cpr-summary">
          <span className="pageslider-cpr-text">The next step is to complete a short survey and you will receive your $10 when you complete it. Click the button below to be redirected to take that survey now.</span>
          <button type="button" className="pageslider-conc-button button" onClick={() => {calculateTimeSpent(Conc);      
            console.log("SurveyButtonClicked Yes");
            adduserdata("SurveyButtonClicked","1");
            var endDate = new Date().toLocaleDateString("en-US", {timeZone: "America/New_York"});
            var endTime = new Date().toLocaleTimeString("en-US", {timeZone: "America/New_York"});
            adduserdata("SurveyClickDateEastern",endDate);
            adduserdata("SurveyClickTimeEastern",endTime);
            increaseTrainingPercentage(100);
            setTimeout(() => {
              window.location.href = `https://eandwresearch.co1.qualtrics.com/jfe/form/SV_40DeY5PyW7B1QUe?ExternalDataReference=${original_username}`;
          }, 2000);
          }}>
            Complete Survey
          </button>
          </div>
        )}
        </div>
      )}
    </div>
  )
}

PagesliderConc.defaultProps = {
  rootClassName: '',
}

PagesliderConc.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderConc
