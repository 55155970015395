import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-cpr.css'
import { Link } from 'react-router-dom'



const PagesliderCPR = (props) => {
  const { increaseTrainingPercentage } = props;

  const [show_summary, setShow_summary] = useState(false)
  const [slideNumberCPR, setSlideNumberCPR] = useState(1)
  const [isPlaying, setIsPlaying] = useState(true);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/cpr');

    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberCPR]);
  
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
  try {
    const resp = await fetch(
      `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
    );
    if (!resp.ok) {
      // throw new Error(`HTTP error! status: ${resp.status}`);
      console.log('Fetch failed:', resp.status);

    }
  } catch (error) {
    console.log('Fetch failed:');
  }
}

const calculateTimeSpent = (slidenumorname) => {
  const timespent=Date.now() - startTime;
  console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
  adduserdata("CPR"+slidenumorname+"Time",timespent)
  setStartTime(Date.now())
}

  const videoMapYN1 = {
    1: "/external/CPR1-1.mp4",
    2: "/external/CPR1-2.mp4",
  };

  function handleButtonClickCPR1(choice) {
    const videoSrc = videoMapYN1[choice];
    const video = document.querySelector(".pageslider-cpr-video");
    video.src = videoSrc;
    video.play();
    setIsPlaying(true);
    console.log("HowCPRYesNoChoice_"+choice);
    adduserdata("HowCPRYesNoChoice",choice);
    calculateTimeSpent('YesNo');
  }

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };


  const handleButtonClick = (newSlideNumber) => {
    calculateTimeSpent(slideNumberCPR);
    setIsPlaying(true);
    setSlideNumberCPR(newSlideNumber);
    setVideoEnded(false);
  }

  return (
    <div className={`pageslider-cpr-container ${props.rootClassName} `}>
      {slideNumberCPR === 1 && (
        <div className="pageslider-cpr-p1">
          <div className="pageslider-cpr-container01">
            <video
              ref={videoRef}
              src="/external/CPR1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded && (
            <div className="pageslider-cpr-summary">
              <span className="pageslider-cpr-text">
                Do you know how to perform CPR?
              </span>
              <ul className="pageslider-cpr-ul list">
                <li className="pageslider-cpr-li button list-item">
                  <div className="pageslider-cpr-container02">
                    <span className="pageslider-cpr-text01"onClick={() => {handleButtonClickCPR1('1');document.querySelector('.pageslider-cpr-summary').remove()}}>Yes</span>
                  </div>
                </li>
                <li className="pageslider-cpr-li01 button list-item">
                  <div className="pageslider-cpr-container03">
                    <span className="pageslider-cpr-text02">
                      <span className="pageslider-cpr-text03" onClick={() => {handleButtonClickCPR1('2');document.querySelector('.pageslider-cpr-summary').remove()}}>No</span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(2)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 2 && (
        <div className="pageslider-cpr-p2">
          <div className="pageslider-cpr-container04">
            <video
              ref={videoRef}
              src="/external/CPR2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary1">
              <span className="pageslider-cpr-text05">CPR Types</span>
              <ul className="pageslider-cpr-ul1 list">
                <li className="pageslider-cpr-li02 list-item">
                  <div className="pageslider-cpr-container05">
                    <span className="pageslider-cpr-text06">
                      Hands-only CPR helps circulate blood
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li03 list-item">
                  <div className="pageslider-cpr-container06">
                    <span className="pageslider-cpr-text07">
                      CPR with breaths helps circulate blood and oxygen
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(3)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 3 && (
        <div className="pageslider-cpr-p3">
          <div className="pageslider-cpr-container07">
            <video
              ref={videoRef}
              src="/external/CPR3.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton2 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary2">
              <span className="pageslider-cpr-text08">
                Hands-only CPR steps
              </span>
              <ul className="pageslider-cpr-ul2 list">
                <li className="pageslider-cpr-li04 list-item">
                  <div className="pageslider-cpr-container08">
                    <span className="pageslider-cpr-text09">
                      Push hard and fast in the center of the chest.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(4)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 4 && (
        <div className="pageslider-cpr-p8">
          <div className="pageslider-cpr-container30">
            <video
              ref={videoRef}
              src="/external/CPR4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              className="pageslider-cpr-summarybutton7 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded && (
            <div className="pageslider-cpr-summary7">
              <span className="pageslider-cpr-text31">
              Would you like to learn CPR with breaths?
              </span>
              <ul className="pageslider-cpr-ul7 list">
                <li className="pageslider-cpr-li22 button list-item">
                  <div className="pageslider-cpr-container31">
                    <span className="pageslider-cpr-text32"onClick={() => {handleButtonClick(5);
                        console.log("CPRBreatheYesNoChoice_Yes");
                        adduserdata("CPRBreatheYesNoChoice","1");
                        }}>Yes</span>
                  </div>
                </li>
                <li className="pageslider-cpr-li24 button list-item">
                  <div className="pageslider-cpr-container33">
                    <span className="pageslider-cpr-text36">
                      <span className="pageslider-cpr-text37"onClick={() => {handleButtonClick(10);
                        console.log("CPRBreatheYesNoChoice_No");
                        adduserdata("CPRBreatheYesNoChoice","2");
                        }}>No</span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(5)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 5 && (
        <div className="pageslider-cpr-p3">
          <div className="pageslider-cpr-container07">
            <video
              ref={videoRef}
              src="/external/CPR5.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton2 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary2">
              <span className="pageslider-cpr-text08">
                Before beginning CPR with breaths
              </span>
              <ul className="pageslider-cpr-ul2 list">
                <li className="pageslider-cpr-li04 list-item">
                  <div className="pageslider-cpr-container08">
                    <span className="pageslider-cpr-text09">
                      Make sure nothing is in the person&apos;s mouth that could
                      block airway and breathing
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li05 list-item">
                  <div className="pageslider-cpr-container09">
                    <span className="pageslider-cpr-text10">
                      Manually clear the person&apos;s mouth and nose with your
                      fingers.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(6)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 6 && (
        <div className="pageslider-cpr-p4">
          <div className="pageslider-cpr-container10">
            <video
              ref={videoRef}
              src="/external/CPR6.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton3 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary3">
              <span className="pageslider-cpr-text11">
                How to perform CPR with breaths
              </span>
              <ul className="pageslider-cpr-ul3 list">
                <li className="pageslider-cpr-li06 list-item">
                  <div className="pageslider-cpr-container11">
                    <span className="pageslider-cpr-text12">
                      First, tilt the person&apos;s head back, lift chin, and
                      pinch the nose shut.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(7)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 7 && (
        <div className="pageslider-cpr-p5">
          <div className="pageslider-cpr-container12">
            <video
              ref={videoRef}
              src="/external/CPR7.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton4 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary4">
              <span className="pageslider-cpr-text13">
                How to perform CPR with breaths
              </span>
              <ul className="pageslider-cpr-ul4 list">
                <li className="pageslider-cpr-li07 list-item">
                  <div className="pageslider-cpr-container13">
                    <span className="pageslider-cpr-text14">
                      First, tilt the person&apos;s head back, lift chin, and
                      pinch the nose shut.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li08 list-item">
                  <div className="pageslider-cpr-container14">
                    <span className="pageslider-cpr-text15">
                      Next, Give one breath into the person&apos;s mouth, which
                      should last about one second.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li09 list-item">
                  <div className="pageslider-cpr-container15">
                    <span className="pageslider-cpr-text16">
                      Blow enough air into the lungs to make the chest rise.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li10 list-item">
                  <div className="pageslider-cpr-container16">
                    <span className="pageslider-cpr-text17">
                      Repeat this step if the person is still not breathing on
                      their own.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(8)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberCPR === 8 && (
        <div className="pageslider-cpr-p6">
          <div className="pageslider-cpr-container17">
            <video
              ref={videoRef}
              src="/external/CPR8.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton5 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary5">
              <span className="pageslider-cpr-text18">
                How to perform CPR with breaths
              </span>
              <ul className="pageslider-cpr-ul5 list">
                <li className="pageslider-cpr-li11 list-item">
                  <div className="pageslider-cpr-container18">
                    <span className="pageslider-cpr-text19">
                      First, tilt the person&apos;s head back, lift chin, and
                      pinch the nose shut.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li12 list-item">
                  <div className="pageslider-cpr-container19">
                    <span className="pageslider-cpr-text20">
                      Next, Give one breath into the person&apos;s mouth, which
                      should last about one second.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li13 list-item">
                  <div className="pageslider-cpr-container20">
                    <span className="pageslider-cpr-text21">
                      Blow enough air into the lungs to make the chest rise.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li14 list-item">
                  <div className="pageslider-cpr-container21">
                    <span className="pageslider-cpr-text22">
                      Repeat this step if the person is still not breathing on
                      their own.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li15 list-item">
                  <div className="pageslider-cpr-container22">
                    <span className="pageslider-cpr-text23">
                      If the person begins to breathe, put the person on their
                      side in the rescue position.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(9)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 9 && (
        <div className="pageslider-cpr-p7">
          <div className="pageslider-cpr-container23">
            <video
              ref={videoRef}
              src="/external/CPR9.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton6 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary6">
              <span className="pageslider-cpr-text24">
                How to perform CPR with breaths
              </span>
              <ul className="pageslider-cpr-ul6 list">
                <li className="pageslider-cpr-li16 list-item">
                  <div className="pageslider-cpr-container24">
                    <span className="pageslider-cpr-text25">
                      First, tilt the person&apos;s head back, lift chin, and
                      pinch the nose shut.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li17 list-item">
                  <div className="pageslider-cpr-container25">
                    <span className="pageslider-cpr-text26">
                      Next, Give one breath into the person&apos;s mouth, which
                      should last about one second.
                    </span>
                  </div>
                </li>https://dash.cloudflare.com/93817e0ace787d0f430c9e3c1b9d0b9f/ai
                <li className="pageslider-cpr-li18 list-item">
                  <div className="pageslider-cpr-container26">
                    <span className="pageslider-cpr-text27">
                      Blow enough air into the lungs to make the chest rise.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li19 list-item">
                  <div className="pageslider-cpr-container27">
                    <span className="pageslider-cpr-text28">
                      Repeat this step if the person is still not breathing on
                      their own.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li20 list-item">
                  <div className="pageslider-cpr-container28">
                    <span className="pageslider-cpr-text29">
                      If the person begins to breathe, put the person on their
                      side in the rescue position.
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li21 list-item">
                  <div className="pageslider-cpr-container29">
                    <span className="pageslider-cpr-text30">
                      Continue to monitor breathing and perform CPR if
                      respirations are fewer than 10 breaths a minute
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
            {videoEnded && (
           <Link to={`/pulsepoint?username=${original_username}`}  className="pageslider-intro-navlink"  onClick={() => {calculateTimeSpent(slideNumberCPR); increaseTrainingPercentage(70);}}>
          <svg viewBox="0 0 1024 1024" className="pageslider-cpr-next8">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
      {slideNumberCPR === 10 && (
        <div className="pageslider-cpr-p8">
          <div className="pageslider-cpr-container30">
            <video
              ref={videoRef}
              src="/external/CPR10.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-cpr-summarybutton6 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-cpr-summary5">
              <span className="pageslider-cpr-text18">
              Hands-only CPR can potentially save a life too!
              </span>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          <Link to={`/pulsepoint?username=${original_username}`} className="pageslider-intro-navlink" onClick={() => {calculateTimeSpent(slideNumberCPR); increaseTrainingPercentage(70);}}>
          <svg viewBox="0 0 1024 1024" className="pageslider-cpr-next8">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          </div>
        </div>
      )}
      {/* {slideNumberCPR === 9 && (
        <div className="pageslider-cpr-p9">
          <div className="pageslider-cpr-container34">
            <video
              src="/external/.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-cpr-video8 video"
            ></video>
            <button
              type="button"
              className="pageslider-cpr-summarybutton8 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            <div className="pageslider-cpr-summary8">
              <span className="pageslider-cpr-text39">
                How to perform Hands-only CPR?
              </span>
              <ul className="pageslider-cpr-ul8 list">
                <li className="pageslider-cpr-li25 list-item">
                  <div className="pageslider-cpr-container35">
                    <span className="pageslider-cpr-text40">
                      Call 911.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-cpr-li26 list-item">
                  <div className="pageslider-cpr-container36">
                    <span className="pageslider-cpr-text41">
                      Push hard and fast in the center of the chest
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}

PagesliderCPR.defaultProps = {
  rootClassName: '',
}

PagesliderCPR.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderCPR
