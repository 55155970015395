import React from 'react'

import { Helmet } from 'react-helmet'

import PagesliderCPR from '../components/pageslider-cpr'
import './cpr.css'

const CPR = (props) => {
  const { increaseTrainingPercentage } = props;

  return (
    <div className="cpr-container">
      <Helmet>
        <title>CPR - ORRS</title>
        <meta property="og:title" content="CPR - ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <PagesliderCPR increaseTrainingPercentage={increaseTrainingPercentage} rootClassName="pageslider-cpr-root-class-name"></PagesliderCPR>
      <footer className="cpr-footer">
        <span className="cpr-text2">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="cpr-text4">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="cpr-text6 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default CPR
