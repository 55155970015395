import React,{ createContext, useContext, useEffect,useState,useRef }  from 'react'

import { Helmet } from 'react-helmet'

import PagesliderSigns from '../components/pageslider-signs'
import './signs.css'

const Signs = (props) => {
  const { increaseTrainingPercentage } = props;

  return (
    <div className="signs-container">
      <Helmet>
        <title>Signs - ORRS</title>
        <meta property="og:title" content="Signs - ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <PagesliderSigns increaseTrainingPercentage={increaseTrainingPercentage} rootClassName="pageslider-signs-root-class-name"></PagesliderSigns>
      <footer className="signs-footer">
        <span className="signs-text2">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="signs-text4">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="signs-text6 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default Signs
