import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-work-narcan.css'
import { Link } from 'react-router-dom'

const PagesliderWorkNARCAN = (props) => {

  const [show_summary, setShow_summary] = useState(false)
  const [slideNumberNARCANWork, setSlideNumberNARCANWork] = useState(1)
  const [isPlaying, setIsPlaying] = useState(true);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};
    localStorage.setItem('lastModule', '/workNARCAN');

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    
    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberNARCANWork]);
  
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
    try {
      const resp = await fetch(
        `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
      );
      if (!resp.ok) {
        // throw new Error(`HTTP error! status: ${resp.status}`);
        console.log('Fetch failed:', resp.status);

      }
    } catch (error) {
      console.log('Fetch failed:');
    }
  }
  const calculateTimeSpent = (slidenumorname) => {
    const timespent=Date.now() - startTime;
    console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
    adduserdata("WorkNarcan"+slidenumorname+"Time",timespent)
    setStartTime(Date.now())
  }

  const videoMapYN = {
    1: "/external/WorkNarcan3-1.mp4",//yes
    2: "/external/WorkNarcan3-2.mp4",//no
  };

  function handleButtonClickWorkNarcan(choice) {
    const videoSrc = videoMapYN[choice];
    const video = document.querySelector(".pageslider-work-narcan-video");
    video.src = videoSrc;
    video.play();
    setIsPlaying(true);
    console.log("WorkNarcanYesNoChoice_"+choice);
    adduserdata("WorkNarcanYesNoChoice",choice);
    calculateTimeSpent('YesNo');
  }

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handleButtonClick = (newSlideNumber) => {
    calculateTimeSpent(slideNumberNARCANWork);
    setIsPlaying(true);
    setSlideNumberNARCANWork(newSlideNumber);
    setVideoEnded(false);
  }

  return (
    <div className={`pageslider-work-narcan-container ${props.rootClassName} `}>
      {slideNumberNARCANWork === 1 && (
        <div className="pageslider-work-narcan-p1">
          <div className="pageslider-work-narcan-container01">
            <video
              ref={videoRef}
              src="/external/WorkNarcan1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-work-narcan-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-work-narcan-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-work-narcan-summary">
              <span className="pageslider-work-narcan-text">
                How giving NARCAN can help?
              </span>
              <ul className="pageslider-work-narcan-ul list">
                <li className="pageslider-work-narcan-li list-item">
                  <div className="pageslider-work-narcan-container02">
                    <span className="pageslider-work-narcan-text01">
                      NARCAN quickly reverses the symptoms of opioid overdose if
                      given in time.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li1 list-item">
                  <div className="pageslider-work-narcan-container03">
                    <span className="pageslider-work-narcan-text02">
                      It can quickly restore breathing if someone has overdosed.
                    </span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li2 list-item">
                  <div className="pageslider-work-narcan-container04">
                    <span className="pageslider-work-narcan-text03">
                      It can be delivered via a shot, nasal spray, or through an
                      IV.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => {handleButtonClick(2)}} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberNARCANWork === 2 && (
        <div className="pageslider-work-narcan-p2">
          <div className="pageslider-work-narcan-container05">
            <video
              ref={videoRef}  
              src="/external/WorkNarcan2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-work-narcan-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-work-narcan-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-work-narcan-summary1">
              <span className="pageslider-work-narcan-text04">
                How NARCAN works?
              </span>
              <ul className="pageslider-work-narcan-ul1 list">
                <li className="pageslider-work-narcan-li3 list-item">
                  <div className="pageslider-work-narcan-container06">
                    <span className="pageslider-work-narcan-text05">
                      Opioids activate opioid receptors on their nerve cells
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li4 list-item">
                  <div className="pageslider-work-narcan-container07">
                    <span className="pageslider-work-narcan-text06">
                      NARCAN blocks the opioids from binding to the opioid
                      receptors in the brain
                    </span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li5 list-item">
                  <div className="pageslider-work-narcan-container08">
                    <span className="pageslider-work-narcan-text07">
                      NARCAN works within 2-3 minutes and lasts 30-90 minutes.
                    </span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li6 list-item">
                  <div className="pageslider-work-narcan-container09">
                    <span className="pageslider-work-narcan-text08">
                      NARCAN buys a person more time for medical help to arrive.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
             )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => {handleButtonClick(3)}} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberNARCANWork === 3 && (
        <div className="pageslider-work-narcan-p3">
          <div className="pageslider-work-narcan-container10">
            <video
              ref={videoRef}  
              src="/external/WorkNarcan3.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-work-narcan-video video"
            ></video>
            <button
              type="button"
              className="pageslider-work-narcan-summarybutton2 button"
            >
            {/* {show_summary ? 'Hide Summary' : 'Show Summary'} */}
            </button>
            {videoEnded && (
            <div className="pageslider-work-narcan-summary2">
              <span className="pageslider-work-narcan-text09">
                You are in a public area and see someone who is unresponsive to
                voice and touch. They are barely breathing. Is it safe to use
                NARCAN even if you are not 100% sure the person was using
                opioids?
              </span>
              <ul className="pageslider-work-narcan-ul2 list">
                <li className="pageslider-work-narcan-li7 button list-item">
                  <div className="pageslider-work-narcan-container11">
                    <span className="pageslider-work-narcan-text10" onClick={() => {handleButtonClickWorkNarcan('1');document.querySelector('.pageslider-work-narcan-summary2').remove()}}>Yes</span>
                  </div>
                </li>
                <li className="pageslider-work-narcan-li8 button list-item">
                  <div className="pageslider-work-narcan-container12">
                    <span className="pageslider-work-narcan-text11">
                      <span className="pageslider-work-narcan-text12" onClick={() => {handleButtonClickWorkNarcan('2');document.querySelector('.pageslider-work-narcan-summary2').remove()}}>No</span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
          <Link to={`/howNARCAN?username=${original_username}`} className="pageslider-intro-navlink" onClick={() => {calculateTimeSpent(slideNumberNARCANWork)}}>
          <svg viewBox="0 0 1024 1024" className="pageslider-work-narcan-next2">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
    </div>
  )
}

PagesliderWorkNARCAN.defaultProps = {
  rootClassName: '',
}

PagesliderWorkNARCAN.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderWorkNARCAN
