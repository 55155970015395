import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-stigma.css'
import { Link } from 'react-router-dom'

const PagesliderStigma = (props) => {
  const [slideNumberStigma, setSlideNumberStigma] = useState(1)
  const [show_summary, setShow_summary] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/stigma');

    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberStigma]);

const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
      try {
        const resp = await fetch(
          `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
        );
        if (!resp.ok) {
          // throw new Error(`HTTP error! status: ${resp.status}`);
          console.log('Fetch failed:', resp.status);

        }
      } catch (error) {
        console.log('Fetch failed:');
      }
    }

    const calculateTimeSpent = (slidenumorname) => {
      const timespent=Date.now() - startTime;
      console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
      adduserdata("Stigma"+slidenumorname+"Time",timespent)
      setStartTime(Date.now())
    }
    const videoMapYN = {
      1: "/external/Stigma1-1.mp4",
      2: "/external/Stigma1-2.mp4",
    };
    
  function handleButtonClickStigma(choice) {
    const videoSrc = videoMapYN[choice];
    const video = document.querySelector(".pageslider-stigma-video");
    video.src = videoSrc;
    video.play();
    setIsPlaying(true);
    console.log("StigmaYesNoChoice_"+choice);
    adduserdata("StigmaChoice",choice);
    calculateTimeSpent('YesNo');
  }
  
 

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
    setVideoEnded(false);
  };

  return (
    <div className="pageslider-stigma-container">
      {slideNumberStigma === 1 && (
        <div className="pageslider-stigma-p1">
          <div className="pageslider-stigma-container1">
            <video
              ref={videoRef}
              src="/external/Stigma1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-stigma-video video"
            ></video>
            <button
              type="button"
              className="pageslider-stigma-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded && (
            <div className="pageslider-stigma-summary">
              <span className="pageslider-stigma-text">
                Do you think administering NARCAN encourages opioid users to
                continue misusing opioids?
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <ul className="pageslider-stigma-ul list">
                <li className="pageslider-stigma-li button list-item">
                  <div className="pageslider-stigma-container2">
                    <span className="pageslider-stigma-text1"onClick={() => {handleButtonClickStigma('1');document.querySelector('.pageslider-stigma-summary').remove()}}>Yes</span>
                  </div>
                </li>
                <li className="pageslider-stigma-li1 button list-item">
                  <div className="pageslider-stigma-container3">
                    <span className="pageslider-stigma-text2">
                      <span className="pageslider-stigma-text3"onClick={() => {handleButtonClickStigma('2');document.querySelector('.pageslider-stigma-summary').remove()}}>No</span>
                      <br></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
          <Link to={`/conclusion?username=${original_username}`} className="pageslider-intro-navlink"  onClick={() => calculateTimeSpent(slideNumberStigma)}>
          <svg viewBox="0 0 1024 1024" className="pageslider-stigma-next">
            <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"></path>
            <path d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
    </div>
  )
}

PagesliderStigma.defaultProps = {
  rootClassName: '',
}

PagesliderStigma.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderStigma
