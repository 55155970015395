import React from 'react'

import { Helmet } from 'react-helmet'

import PagesliderWorkNARCAN from '../components/pageslider-work-narcan'
import './work-narcan.css'

const WorkNARCAN = (props) => {

  return (
    <div className="work-narcan-container">
      <Helmet>
        <title>WorkNARCAN - ORRS</title>
        <meta property="og:title" content="WorkNARCAN - ORRS" />
      </Helmet>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
      <PagesliderWorkNARCAN rootClassName="pageslider-work-narcan-root-class-name"></PagesliderWorkNARCAN>
      <footer className="work-narcan-footer">
        <span className="work-narcan-text2">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="work-narcan-text4">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="work-narcan-text6 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

export default WorkNARCAN
