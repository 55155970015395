import React from 'react';
import './orrsmodules.css';

const components = [
  { name: 'Introduction', path: '/introduction' },
  { name: 'Signs of overdose', path: '/signs' },
  { name: 'Safety', path: '/safety' },
  { name: 'How NARCAN works?', path: '/workNARCAN' },
  { name: 'How to use NARCAN?', path: '/howNARCAN' },
  { name: 'How to perform CPR?', path: '/cpr' },
  { name: 'How to use PulsePoint?', path: '/pulsepoint' },
  { name: 'Does using NARCAN encourage misusing opioids?', path: '/stigma' },
  { name: 'Conclusion', path: '/conclusion' },
];

function ORRSModules() {
  return (
    <div className='modules-container'>
      <div className="home-header">
        <div className="header-content">
            <img id="logo" src="\external\ORRSLogos_1113.png" alt="Logo" />
            <h1 className="home-text">The Opioid Rapid Response System (ORRS)<sup className='trademark'>TM</sup></h1>
        </div>
    </div>
        <div className="training-header">
        <h2>Welcome to ORRS Training Modules!</h2>
        <p>ORRS training is divided into individual modules to help you revise as needed. Feel free to revisit any module for revision.</p>
      </div>
      <div className="orrs-modules">
        {components.map((component, index) => (
          <button
            key={index}
            onClick={() => window.open(component.path, '_blank')}
          >
            {component.name}
          </button>
        ))}
      </div>
        <footer className="introduction-footer">
        <span className="introduction-text1">
          <span>
            The contents of this training were created by REAL Prevention. For
            inquiries on permission for use of these materials please contact
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="introduction-text3">hechtpsu@gmail.com.</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </span>
        <span className="introduction-text5 Content">
          Copyright (2021) REAL Prevention. All rights reserved.
        </span>
      </footer>
    </div>
  );
}
export default ORRSModules;