import React, { useState,useRef,useEffect } from 'react'
import PropTypes, { func } from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-signs.css'
import { Link } from 'react-router-dom'

const PagesliderSigns = (props) => {
  const { increaseTrainingPercentage } = props;

  const [slideNumberSigns, setSlideNumberSigns] = useState(1)
  const [show_summary, setShow_summary] = useState(false)
  const [startTime, setStartTime] = useState(Date.now())
  const [videoEnded, setVideoEnded] = useState(false);

  const [signs, setSigns] = useState([
    { id: 1, text: 'Unresponsive', checked: false },
    { id: 2, text: 'Breathing with difficulty', checked: false },
    { id: 3, text: 'Breathing slowly', checked: false },
    { id: 4, text: 'Not breathing at all', checked: false },
  ]);

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/signs');

    return () => {
      // unlisten();
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberSigns]);
  
    const { username, original_username } = useUser();
    const adduserdata = async (field, data) => {
      try {
        const resp = await fetch(
          `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
        );
        if (!resp.ok) {
          // throw new Error(`HTTP error! status: ${resp.status}`);
          console.log('Fetch failed:', resp.status);
  
        }
      } catch (error) {
        console.log('Fetch failed:');
      }
    }

    const calculateTimeSpent = () => {
      const timespent=Date.now() - startTime
      console.log(`Time spent on slide ${slideNumberSigns}: ${timespent}ms`)
      adduserdata("Signs"+slideNumberSigns+"Time",timespent)
      setStartTime(Date.now())
    }

    const handlePlayPause = () => {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
  
      }
    };
  
  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handleSignClick = (id) => {
    const updatedSigns = signs.map((sign) => {
      if (sign.id === id) {
        return { ...sign, checked: true };
      }
      return sign;
    });
    setSigns(updatedSigns);
  };

  function allSignsSelected() {
    return signs.every((sign) => sign.checked);
  }

  const videoMapSAVE = {
    S: "/external/Sign4-1.mp4",
    A: "/external/Sign4-2.mp4",
    V: "/external/Sign4-3.mp4",
    E: "/external/Sign4-4.mp4"
  };

  const [savechecked, setSavechecked] = useState([
    { id: 'S', checked: false },
    { id: 'A', checked: false },
    { id: 'V', checked: false },
    { id: 'E', checked: false },
  ]);
  function handleButtonClickSAVE(letter) {
    const videoSrc = videoMapSAVE[letter];
    const video = document.querySelector(".pageslider-signs-video");
    video.src = videoSrc;
    video.play();
    console.log("Signs_SAVE_"+letter+"_Clicked 1");
    adduserdata("Signs_SAVE_"+letter+"_Clicked",'1');

    setSavechecked(prevState => prevState.map(item => 
      item.id === letter ? { ...item, checked: true } : item
    ));
  }

  function allLettersClicked() {
    return savechecked.every(item => item.checked);
  }
  return (
    <div className={`pageslider-signs-container ${props.rootClassName} `}>
      {slideNumberSigns === 1 && (
        <div className="pageslider-signs-p1">
          <div className="pageslider-signs-container01">
            <video
              ref={videoRef}  
              src="/external/Sign1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-signs-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
              <div className="pageslider-signs-summary">
                <span className="pageslider-signs-text">Opioid overdoses</span>
                <ul className="pageslider-signs-ul list">
                  <li className="pageslider-signs-li list-item">
                    <div className="pageslider-signs-container02">
                      <span className="pageslider-signs-text01">
                        An overdose is when someone takes too much of a drug and
                        has life-threatening symptoms.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-signs-li01 list-item">
                    <div className="pageslider-signs-container03">
                      <span className="pageslider-signs-text02">
                        Learn how to recognize and respond to an overdose.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => {setSlideNumberSigns(2); calculateTimeSpent();setVideoEnded(false);}} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
           )}
          </div>
        </div>
      )}
      {slideNumberSigns === 2 && (
        <div className="pageslider-signs-p2">
          <div className="pageslider-signs-container04">
            <video
              ref={videoRef}
              src="/external/Sign2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-signs-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {videoEnded &&(
              <div className="pageslider-signs-summary1">
                <span className="pageslider-signs-text03">
                  Click on each symptom below if you think it is a sign of an
                  opioid overdose and hit done.
                </span>
                <ul className="pageslider-signs-ul1 list">
                  {signs.map((sign) => (
                    <li key={sign.id} className={`pageslider-signs-li01${sign.id} button list-item ${sign.checked ? 'checked' : ''}`} onClick={() => handleSignClick(sign.id)}
                    style={{ backgroundColor: sign.checked ? '#90EE90' : '', marginBottom: '10px'  }} // add this line
                    >
                      <div className={`pageslider-signs-container0${sign.id + 4}`}>
                        <span className={`pageslider-signs-text0${sign.id + 3}`}>
                          {sign.text}
                        </span>
                        {sign.checked && <span className="check-mark">&nbsp;&nbsp;&#10003;</span>}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => {setSlideNumberSigns(3); calculateTimeSpent();  
              const checkedValues = signs.filter(sign => sign.checked).map(sign => sign.id);
              console.log("Selected symptoms:", checkedValues.join(','));
              
              // Initialize an object to hold the symptom data
              let symptomData = {
                  Sign2SymptomNums1: 0,
                  Sign2SymptomNums2: 0,
                  Sign2SymptomNums3: 0,
                  Sign2SymptomNums4: 0
              };
              
              // Update the symptom data based on the checked values
              checkedValues.forEach(value => {
                  symptomData[`Sign2SymptomNums${value}`] = 1;
              });
              
              // Add the symptom data
              for (let [field, data] of Object.entries(symptomData)) {
                  adduserdata(field, data);
              }
              
              setVideoEnded(false);
            }
              
              } className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberSigns === 3 && (
        <div className="pageslider-signs-p3">
          <div className="pageslider-signs-container09">
            <video
              ref={videoRef}  
              src={allSignsSelected() ? "/external/Sign3-1.mp4" : "/external/Sign3-2.mp4"}
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-signs-summarybutton2 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            
            {show_summary && (
            <div className="pageslider-signs-summary2">
              <span className="pageslider-signs-text08">
                Signs of an Overdose
              </span>
              <ul className="pageslider-signs-ul2 list">
                <li className="pageslider-signs-li06 list-item">
                  <div className="pageslider-signs-container10">
                    <span className="pageslider-signs-text09">
                        Unresponsive
                    </span>
                  </div>
                </li>
                <li className="pageslider-signs-li07 list-item">
                  <div className="pageslider-signs-container11">
                    <span className="pageslider-signs-text10">
                        Breathing with difficulty
                    </span>
                  </div>
                </li>
                <li className="pageslider-signs-li08 list-item">
                  <div className="pageslider-signs-container12">
                    <span className="pageslider-signs-text11">
                        Breathing slowly
                    </span>
                  </div>
                </li>
                <li className="pageslider-signs-li09 list-item">
                  <div className="pageslider-signs-container13">
                    <span className="pageslider-signs-text12">
                        Not breathing at all
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>

          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => {setSlideNumberSigns(4); calculateTimeSpent();setVideoEnded(false);}} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
            )}
          </div>
        </div>
      )}
      {slideNumberSigns === 4 && (
        <div className="pageslider-signs-p4">
          <div className="pageslider-signs-container14">
            <video
              ref={videoRef}
              src="/external/Sign4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video video"
            ></video>
            <button
              type="button"
              className="pageslider-signs-summarybutton3 button"
            >
              Show Summary
            </button>
            <div className="pageslider-signs-summary3">
              <span className="pageslider-signs-text13">
                Click each letter below to find out what it stands for
              </span>
              <ul className="pageslider-signs-ul3 list">
                <li className="pageslider-signs-li10 button list-item" onClick={() => {handleButtonClickSAVE('S');document.querySelector('.pageslider-signs-text14').textContent = 'S: Stay with the Person';setVideoEnded(false)}}>
                  <div className="pageslider-signs-container15">
                    <span className="pageslider-signs-text14">S</span>
                  </div>
                </li>
                <li className="pageslider-signs-li11 button list-item" onClick={() => {handleButtonClickSAVE('A');document.querySelector('.pageslider-signs-text15').textContent = 'A: Administer NARCAN';setVideoEnded(false)}}>
                  <div className="pageslider-signs-container16">
                    <span className="pageslider-signs-text15">A</span>
                  </div>
                </li>
                <li className="pageslider-signs-li12 button list-item" onClick={() => {handleButtonClickSAVE('V');document.querySelector('.pageslider-signs-text16').textContent = 'V: Ventilate and Perform CPR';setVideoEnded(false)}}>
                  <div className="pageslider-signs-container17">
                    <span className="pageslider-signs-text16">V</span>
                  </div>
                </li>
                <li className="pageslider-signs-li13 button list-item" onClick={() => {handleButtonClickSAVE('E'); document.querySelector('.pageslider-signs-text17').textContent = 'E: Evaluate the condition';setVideoEnded(false)}}>
                  <div className="pageslider-signs-container18">
                    <span className="pageslider-signs-text17">E</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && allLettersClicked() && (
          <Link to={`/safety?username=${original_username}`} className="pageslider-intro-navlink" onClick={() => { calculateTimeSpent(); increaseTrainingPercentage(28); }}> 
          {/* onClick={calculateTimeSpent} */}
          <svg viewBox="0 0 1024 1024" className="pageslider-signs-next4">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
    </div>
  )
}

PagesliderSigns.defaultProps = {
  rootClassName: '',
}

PagesliderSigns.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderSigns
