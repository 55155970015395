import React, { useState,useRef,useEffect } from 'react'

import PropTypes, { func } from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-signs.css'
import { Link } from 'react-router-dom'

const PagesliderSafety = (props) => {
  const { increaseTrainingPercentage } = props;

  const [slideNumberSigns, setSlideNumberSigns] = useState(5)
  const [show_summary, setShow_summary] = useState(false)
  const [startTime, setStartTime] = useState(Date.now())
  const [linkDestination, setLinkDestination] = useState('/howNARCAN');
  const [videoEnded, setVideoEnded] = useState(false);

  const [signs, setSigns] = useState([
    { id: 1, text: 'Blue lips and fingernails', checked: false },
    { id: 2, text: 'Pin-point pupils', checked: false },
    { id: 3, text: 'Breathing or heartbeat slowing down or stopping', checked: false },
    { id: 4, text: 'Unresponsive to your voice or touch', checked: false },
  ]);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};
    localStorage.setItem('lastModule', '/safety');

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    
    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberSigns]);
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
  try {
    const resp = await fetch(
      `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
    );
    if (!resp.ok) {
      // throw new Error(`HTTP error! status: ${resp.status}`);
      console.log('Fetch failed:', resp.status);

    }
  } catch (error) {
    console.log('Fetch failed:');
  }
}

const calculateTimeSpent = () => {
  const timespent=Date.now() - startTime
  console.log(`Time spent on slide ${slideNumberSigns}: ${timespent}ms`)
  adduserdata("Safety"+slideNumberSigns+"Time",timespent)
  setStartTime(Date.now())
}
    const handlePlayPause = () => {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
  
      }
    };
  
    const handleReplay = () => {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setIsPlaying(true);
    };

  const handleSignClick = (id) => {
    const updatedSigns = signs.map((sign) => {
      if (sign.id === id) {
        return { ...sign, checked: true };
      }
      return sign;
    });
    setSigns(updatedSigns);
  };

  function allSignsSelected() {
    return signs.every((sign) => sign.checked);
  }

  const videoMapABC = {
    A: "/external/safety1-1.mp4",
    B: "/external/safety1-2.mp4",
    C: "/external/safety1-3.mp4",
    D: "/external/safety1-4.mp4",
    E: "/external/safety1-5.mp4"
  };
  const [ABCDEchecked, setABCDEchecked] = useState([
    { id: 'A', checked: false },
    { id: 'B', checked: false },
    { id: 'C', checked: false },
    { id: 'D', checked: false },
    { id: 'E', checked: false },
  ]);
  function handleButtonClickABC(letter) {
    const videoSrc = videoMapABC[letter];
    const video = document.querySelector(".pageslider-signs-video4");
    video.src = videoSrc;
    video.play();
    console.log("Safety_ABC_"+letter+"_Clicked 1");
    adduserdata("Safety_ABC_"+letter+"_Clicked",'1');

    setABCDEchecked(prevState => prevState.map(item => 
      item.id === letter ? { ...item, checked: true } : item
    ));
  }

  function allLettersClicked() {
    return ABCDEchecked.every(item => item.checked);
  }

  const videoMapYNMay = {
    1: "/external/Sign6-1.mp4",//yes
    2: "/external/Sign6-23.mp4",//no
    3: "/external/Sign6-23.mp4",//maybe
  };
  function handleButtonClickYNMay(response) {
    const videoSrc = videoMapYNMay[response];
    const video = document.querySelector(".pageslider-signs-video");
    video.src = videoSrc;
    video.play();
    console.log("Watch_HowNarcan_"+response);
    adduserdata("Watch_HowNarcan",response);
    if (response === '1') {
      setLinkDestination(`/workNarcan?username=${original_username}`);
    } else if (response === '2') {
      setLinkDestination(`/howNarcan?username=${original_username}`);
    } else if (response === '3') {
      setLinkDestination(`/howNarcan?username=${original_username}`);
    }
  }


  return (
    <div className={`pageslider-signs-container ${props.rootClassName} `}>
      {slideNumberSigns === 5 && (
        <div className="pageslider-signs-p5">
          <div className="pageslider-signs-container19">
            <video
              ref={videoRef}
              src="/external/safety1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video4 video"
            ></video>
            <button
              type="button"
              className="pageslider-signs-summarybutton4 button"
            >
              Show Summary
            </button>
            {videoEnded && (
            <div className="pageslider-signs-summary4">
              <span className="pageslider-signs-text18">
                Click on each letter below to reveal a component of staying safe
                in an emergency.
              </span>
              <ul className="pageslider-signs-ul4 list">
                <li className="pageslider-signs-li14 button list-item" onClick={() => {handleButtonClickABC('A');document.querySelector('.pageslider-signs-text19').textContent = 'A: Assess the Scene'}}>
                  <div className="pageslider-signs-container20">
                    <span className="pageslider-signs-text19">A</span>
                  </div>
                </li>
                <li className="pageslider-signs-li15 button list-item" onClick={() => {handleButtonClickABC('B');document.querySelector('.pageslider-signs-text20').textContent = 'B: Be Aware of Hazards'}}>
                  <div className="pageslider-signs-container21">
                    <span className="pageslider-signs-text20">B</span>
                  </div>
                </li>
                <li className="pageslider-signs-li16 button list-item" onClick={() => {handleButtonClickABC('C');document.querySelector('.pageslider-signs-text21').textContent = 'C: Call for Help'}}>
                  <div className="pageslider-signs-container22">
                    <span className="pageslider-signs-text21">C</span>
                  </div>
                </li>
                <li className="pageslider-signs-li17 button list-item" onClick={() => {handleButtonClickABC('D');document.querySelector('.pageslider-signs-text22').textContent = 'D: Distance'}}>
                  <div className="pageslider-signs-container23">
                    <span className="pageslider-signs-text22">D</span>
                  </div>
                </li>
                <li className="pageslider-signs-li18 button list-item" onClick={() => {handleButtonClickABC('E');document.querySelector('.pageslider-signs-text23').textContent = 'E: Ensure Personal Safety'}}>
                  <div className="pageslider-signs-container24">
                    <span className="pageslider-signs-text23">E</span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && allLettersClicked() && (
            <svg viewBox="0 0 1024 1024" onClick={() => {setSlideNumberSigns(6); calculateTimeSpent();setVideoEnded(false);}} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberSigns === 6 && (
        <div className="pageslider-signs-p4">
          <div className="pageslider-signs-container14">
            <video
              ref={videoRef}
              src="/external/Sign6.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-signs-video video"
            ></video>
            <button
              type="button"
              className="pageslider-signs-summarybutton3 button"
            >
              Show Summary
            </button>
            {videoEnded && (
            <div className="pageslider-signs-summary3">
              <span className="pageslider-signs-text13">
              Would you like to learn how NARCAN works to reverse opioid overdoses?
              </span>
              <ul className="pageslider-signs-ul3 list">
                <li className="pageslider-signs-li10 button list-item" onClick={() => {handleButtonClickYNMay('1');document.querySelector('.pageslider-signs-summary3').remove()}}>
                  <div className="pageslider-signs-container15">
                    <span className="pageslider-signs-text14">Yes</span>
                  </div>
                </li>
                <li className="pageslider-signs-li11 button list-item" onClick={() => {handleButtonClickYNMay('2');document.querySelector('.pageslider-signs-summary3').remove()}}>
                  <div className="pageslider-signs-container16">
                    <span className="pageslider-signs-text15">No</span>
                  </div>
                </li>
                <li className="pageslider-signs-li12 button list-item" onClick={() => {handleButtonClickYNMay('3');document.querySelector('.pageslider-signs-summary3').remove()}}>
                  <div className="pageslider-signs-container17">
                    <span className="pageslider-signs-text16">Maybe Later</span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
          <Link to={linkDestination} className="pageslider-intro-navlink" onClick={() => { calculateTimeSpent(); increaseTrainingPercentage(42); }}> 
          {/* onClick={calculateTimeSpent} */}
          <svg viewBox="0 0 1024 1024" className="pageslider-signs-next4">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
    </div>
  )
}

PagesliderSafety.defaultProps = {
  rootClassName: '',
}

PagesliderSafety.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderSafety
