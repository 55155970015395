import React, { useState } from 'react';

import './downloaddata.css'

const DownloadData = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleDownloadClick = () => {
    const url = `https://orrsdbworker.mohanzalake.workers.dev/api/downloaddata?username=${username}&password=${password}`;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error('Error downloading data:', error));
  };

  return (
    <div>
      <h1>Download Data</h1>
      <label>
        Username:
        <input type="text" value={username} onChange={handleUsernameChange} />
      </label>
      <br />
      <label>
        Password:
        <input type="password" value={password} onChange={handlePasswordChange} />
      </label>
      <br />
      <button class="button_download" onClick={handleDownloadClick}>Download</button>
    </div>
  );
};

export default DownloadData;