import React, { useState, useEffect , useRef } from 'react'

import PropTypes from 'prop-types'
import { useUser } from '../index.js';

import './pageslider-how-narcan.css'
import { Link } from 'react-router-dom'

const PagesliderHowNARCAN = (props) => {
  const { increaseTrainingPercentage } = props;

  const [slideNumberNARCAN, setSlideNumberNARCAN] = useState(1)
  const [show_summary, setShow_summary] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true);
  const [startTime, setStartTime] = useState(Date.now())
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);
    const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};

    if (!videoRef.current.paused) {
      setIsPlaying(true);
    }
    if (videoRef.current.paused) {
      setIsPlaying(false);
    }
    videoRef.current.addEventListener('play', onPlay);
    videoRef.current.addEventListener('pause', onPause);
    videoRef.current.addEventListener('ended', onEnded);
    localStorage.setItem('lastModule', '/howNARCAN');

    return () => {
      videoElement.removeEventListener('play', onPlay);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('ended', onEnded);
    };
}, [slideNumberNARCAN]);
  
const { username, original_username } = useUser();
const adduserdata = async (field, data) => {
    try {
      const resp = await fetch(
        `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
      );
      if (!resp.ok) {
        // throw new Error(`HTTP error! status: ${resp.status}`);
        console.log('Fetch failed:', resp.status);

      }
    } catch (error) {
      console.log('Fetch failed:');
    }
  }

  const calculateTimeSpent = (slidenumorname) => {
    const timespent=Date.now() - startTime;
    console.log(`Time spent on slide ${slidenumorname}: ${timespent}ms`)
    adduserdata("HowNarcan"+slidenumorname+"Time",timespent)
    setStartTime(Date.now())
  }

  const videoMapYN = {
    1: "/external/HowNarcan1-1.mp4",//yes
    2: "/external/HowNarcan1-2.mp4",//no
  };

  function handleButtonClickUseNarcan(choice) {
    const videoSrc = videoMapYN[choice];
    const video = document.querySelector(".pageslider-how-narcan-video");
    video.src = videoSrc;
    video.play();
    setIsPlaying(true);
    console.log("HowNarcanYesNoChoice_"+choice);
    adduserdata("HowNarcanYesNoChoice",choice);
    calculateTimeSpent('YesNo');

  }
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };


  const handleButtonClick = (newSlideNumber) => {
    calculateTimeSpent(slideNumberNARCAN);
    setIsPlaying(true);
    setSlideNumberNARCAN(newSlideNumber);
    setVideoEnded(false);
  }

  return (
    <div className={`pageslider-how-narcan-container ${props.rootClassName} `}>
      {slideNumberNARCAN === 1 && (
        <div className="pageslider-how-narcan-p1">
          <div className="pageslider-how-narcan-container01">
            <video
              ref={videoRef}
              src="/external/HowNarcan1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-how-narcan-video video"
            ></video>
            <button
              type="button"
              className="pageslider-how-narcan-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {(videoEnded) && (
            <div className="pageslider-how-narcan-summary">
              <span className="pageslider-how-narcan-text">
                Have you ever used NARCAN before?
              </span>
              <ul className="pageslider-how-narcan-ul list">
                <li className="pageslider-how-narcan-li button list-item">
                  <div className="pageslider-how-narcan-container02">
                    <span className="pageslider-how-narcan-text01" onClick={() => {handleButtonClickUseNarcan('1');document.querySelector('.pageslider-how-narcan-summary').remove()}}>Yes</span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li01 button list-item">
                  <div className="pageslider-how-narcan-container03">
                    <span className="pageslider-how-narcan-text02">
                      <span className="pageslider-how-narcan-text03"onClick={() => {handleButtonClickUseNarcan('2');document.querySelector('.pageslider-how-narcan-summary').remove()}}>No</span>
                      <br className=""></br>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(2)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberNARCAN === 2 && (
        <div className="pageslider-how-narcan-p2">
          <div className="pageslider-how-narcan-container04">
            <video
              ref={videoRef}
              src="/external/HowNarcan2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-how-narcan-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-how-narcan-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-how-narcan-summary1">
              <span className="pageslider-how-narcan-text05">
                NARCAN saves lives
              </span>
              <ul className="pageslider-how-narcan-ul1 list">
                <li className="pageslider-how-narcan-li02 list-item">
                  <div className="pageslider-how-narcan-container05">
                    <span className="pageslider-how-narcan-text06">
                      People with no medical training can administer NARCAN
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li03 list-item">
                  <div className="pageslider-how-narcan-container06">
                    <span className="pageslider-how-narcan-text07">
                      NARCAN has reversed an opioid overdose for more than
                      26,000 people
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(3)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberNARCAN === 3 && (
        <div className="pageslider-how-narcan-p3">
          <div className="pageslider-how-narcan-container07">
            <video
              ref={videoRef}
              src="/external/HowNarcan3.mp4"
              // poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-how-narcan-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-how-narcan-summarybutton2 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-how-narcan-summary2">
              <span className="pageslider-how-narcan-text08">
                How to use NARCAN?
              </span>
              <ul className="pageslider-how-narcan-ul2 list">
                <li className="pageslider-how-narcan-li04 list-item">
                  <div className="pageslider-how-narcan-container08">
                    <span className="pageslider-how-narcan-text09">
                      Remove the NARCAN nasal spray from the box.
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li05 list-item">
                  <div className="pageslider-how-narcan-container09">
                    <span className="pageslider-how-narcan-text10">
                      Review the printed quick start guide in the box.
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li06 list-item">
                  <div className="pageslider-how-narcan-container10">
                    <span className="pageslider-how-narcan-text11">
                      Hold the NARCAN nasal spray with your thumb on the bottom
                      of the plunger
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li07 list-item">
                  <div className="pageslider-how-narcan-container11">
                    <span className="pageslider-how-narcan-text12">
                      Tilt the person&apos;s head back and provide support under
                      the neck with your hand.
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li08 list-item">
                  <div className="pageslider-how-narcan-container12">
                    <span className="pageslider-how-narcan-text13">
                      Gently insert the tip of the nozzle into one nostril
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li09 list-item">
                  <div className="pageslider-how-narcan-container13">
                    <span className="pageslider-how-narcan-text14">
                      Press the plunger firmly to give the full dose of NARCAN
                      nasal spray.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(4)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumberNARCAN === 4 && (
        <div className="pageslider-how-narcan-p4">
          <div className="pageslider-how-narcan-container14">
            <video
              ref={videoRef}
              src="/external/HowNarcan4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-how-narcan-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-how-narcan-summarybutton3 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
            <div className="pageslider-how-narcan-summary3">
              <span className="pageslider-how-narcan-text15">
                After administring NARCAN
              </span>
              <ul className="pageslider-how-narcan-ul3 list">
                <li className="pageslider-how-narcan-li10 list-item">
                  <div className="pageslider-how-narcan-container15">
                    <span className="pageslider-how-narcan-text16">
                    If you did not get a PulsePoint notification, call 911, even if the patient is awake and looks well.                    </span>
                  </div>
                </li>
                <li className="pageslider-how-narcan-li11 list-item">
                  <div className="pageslider-how-narcan-container16">
                    <span className="pageslider-how-narcan-text17">
                      Longer-acting opioids can outlast the effects of NARCAN
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
          <Link to={`/cpr?username=${original_username}`} className="pageslider-intro-navlink"  onClick={() => {calculateTimeSpent(slideNumberNARCAN); increaseTrainingPercentage(56);}}>
          <svg viewBox="0 0 1024 1024" className="pageslider-how-narcan-next3">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg>
          </Link>
          )}
          </div>
        </div>
      )}
    </div>
  )
}

PagesliderHowNARCAN.defaultProps = {
  rootClassName: '',
}

PagesliderHowNARCAN.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderHowNARCAN
