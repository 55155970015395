import React, { useState, useEffect , useRef} from 'react'
import { useUser } from '../index.js';

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './pageslider-intro.css'

const PagesliderIntro = (props) => {
  const { increaseTrainingPercentage } = props;

  const [show_summary, setShow_summary] = useState(false)
  const [slideNumber, setSlideNumber] = useState(1)
  const [startTime, setStartTime] = useState(Date.now())
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
      const videoElement = videoRef.current;
      const onPlay = () => setIsPlaying(true);
      const onPause = () => setIsPlaying(false);
      const onEnded = () => {setIsPlaying(false);setVideoEnded(true)};
  
      if (!videoRef.current.paused) {
        setIsPlaying(true);
      }
      if (videoRef.current.paused) {
        setIsPlaying(false);
      }
      videoRef.current.addEventListener('play', onPlay);
      videoRef.current.addEventListener('pause', onPause);
      videoRef.current.addEventListener('ended', onEnded);
      localStorage.setItem('lastModule', '/introduction');

      return () => {
        videoElement.removeEventListener('play', onPlay);
        videoElement.removeEventListener('pause', onPause);
        videoElement.removeEventListener('ended', onEnded);
      };
  }, [slideNumber]);
  
  const { username, original_username } = useUser();
  const adduserdata = async (field, data) => {
    try {
      const resp = await fetch(
        `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`
      );
      if (!resp.ok) {
        // throw new Error(`HTTP error! status: ${resp.status}`);
        console.log('Fetch failed:', resp.status);

      }
    } catch (error) {
      console.log('Fetch failed');
    }
  }

  const calculateTimeSpent = () => {
    const timespent=Date.now() - startTime
    console.log(`Time spent on slide ${slideNumber}: ${timespent}ms`)
    adduserdata("Intro"+slideNumber+"Time",timespent)
    setStartTime(Date.now())
  }

  
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);

    }
  };

  const handleReplay = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
  };
  // useEffect(() => {
  //   const timespent=Date.now() - startTime
  //   console.log(`Time spent on slide ${slideNumber}: ${timespent}ms`)
  //   adduserdata("Intro"+slideNumber+"Time",timespent)
  //   setStartTime(Date.now())
  // }, [slideNumber])

  const handleButtonClick = (newSlideNumber) => {
    console.log(`Button clicked to go to slide ${newSlideNumber}`)
    calculateTimeSpent();
    setIsPlaying(true);
    setSlideNumber(newSlideNumber);
    setVideoEnded(false);
  }

  return (
    <div className={`pageslider-intro-container ${props.rootClassName} `}>
      {slideNumber === 1 && (
        <div className="pageslider-intro-p1">
          <div className="pageslider-intro-container01">
            <video
              ref={videoRef}
              src="/external/Intro1.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-intro-video video"
            >
            {/* <track kind="subtitles" src="/external/test.srt" srcLang="en" default/> */}
            </video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-intro-summarybutton button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
              <div className="pageslider-intro-summary">
                <span className="pageslider-intro-text">
                  Opioid Rapid Response System
                </span>
                <ul className="pageslider-intro-ul list">
                  <li className="pageslider-intro-li list-item">
                    <div className="pageslider-intro-container02">
                      <span className="pageslider-intro-text01">
                        Be a hero in your community.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li01 list-item">
                    <div className="pageslider-intro-container03">
                      <span className="pageslider-intro-text02">
                        You do not have to have any medical background or
                        training to complete this training.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
        {videoEnded && (         
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(2)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
        )}
        </div>
        </div>
      )}
      {slideNumber === 2 && (
        <div className="pageslider-intro-p2">
          <div className="pageslider-intro-container04">
            <video
              ref={videoRef}
              src="/external/Intro2.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-intro-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-intro-summarybutton1 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
              <div className="pageslider-intro-summary1">
                <span className="pageslider-intro-text03">
                  Learning Objectives
                </span>
                <ul className="pageslider-intro-ul1 list">
                  <li className="pageslider-intro-li02 list-item">
                    <div className="pageslider-intro-container05">
                      <span className="pageslider-intro-text04">
                        Assess the signs and symptoms of an overdose.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li03 list-item">
                    <div className="pageslider-intro-container06">
                      <span className="pageslider-intro-text05">
                        Demonstrate the steps of administering NARCAN.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li04 list-item">
                    <div className="pageslider-intro-container07">
                      <span className="pageslider-intro-text06">
                        Summarize the steps of CPR.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li05 list-item">
                    <div className="pageslider-intro-container08">
                      <span className="pageslider-intro-text07">
                        Use the PulsePoint application and understand
                        notifications.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
          {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(3)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          )}
          </div>
        </div>
      )}
      {slideNumber === 3 && (
        <div className="pageslider-intro-p3">
          <div className="pageslider-intro-container09">
            <video
              ref={videoRef}
              src="/external/Intro3.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-intro-video video"
            ></video>
            {videoEnded && (
              <div className="pageslider-intro-summary2">
                <span className="pageslider-intro-text08">
                  What is your motivation for completing the Opioid Rapid
                  Response training? (Select all that apply)
                </span>
                <ul className="pageslider-intro-ul2 list">
                  <li className="pageslider-intro-li06 list-item">
                    <div className="pageslider-intro-container10">
                      <div className="pageslider-intro-container11">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox"
                        />
                      </div>
                      <div className="pageslider-intro-container12">
                        <span className="pageslider-intro-text09">
                          I feel good when I know I am helping.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pageslider-intro-li07 list-item">
                    <div className="pageslider-intro-container13">
                      <div className="pageslider-intro-container14">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox1"
                        />
                      </div>
                      <div className="pageslider-intro-container15">
                        <span className="pageslider-intro-text10">
                          This training will look good on my resume.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pageslider-intro-li08 list-item">
                    <div className="pageslider-intro-container16">
                      <div className="pageslider-intro-container17">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox2"
                        />
                      </div>
                      <div className="pageslider-intro-container18">
                        <span className="pageslider-intro-text11">
                          I know someone who has overdosed, and this training is
                          dedicated to them.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pageslider-intro-li09 list-item">
                    <div className="pageslider-intro-container19">
                      <div className="pageslider-intro-container20">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox3"
                        />
                      </div>
                      <div className="pageslider-intro-container21">
                        <span className="pageslider-intro-text12">
                          I love my community and want to help make it safer.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pageslider-intro-li10 list-item">
                    <div className="pageslider-intro-container22">
                      <div className="pageslider-intro-container23">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox4"
                        />
                      </div>
                      <div className="pageslider-intro-container24">
                        <span className="pageslider-intro-text13">
                          This training will benefit my entire county.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="pageslider-intro-li11 list-item">
                    <div className="pageslider-intro-container25">
                      <div className="pageslider-intro-container26">
                        <input
                          type="checkbox"
                          className="pageslider-intro-checkbox5"
                        />
                      </div>
                      <div className="pageslider-intro-container27">
                        <span className="pageslider-intro-text14">
                          I want to help prevent opioid overdose deaths by
                          reversing overdoses.
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <svg viewBox="0 0 1024 1024"            onClick={() => {handleButtonClick(4)
            
            const checkboxes = document.querySelectorAll('[class^="pageslider-intro-checkbox"]');
            const checkedCheckboxes = Array.from(checkboxes).filter(
              (checkbox) => checkbox.checked
            );
            if(checkedCheckboxes!=undefined){
            const checkedValues = checkedCheckboxes.map(
              (checkbox) => checkbox.parentNode.parentNode.childNodes[1].childNodes[0].textContent
            );
            console.log("Selected checkboxes:", checkedValues.join(','));
            adduserdata("Intro3MotivationChoiceNums", checkedValues.join(';'))
            }
          }} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
           )}
          </div>
        </div>
      )}
      {slideNumber === 4 && (
        <div className="pageslider-intro-p4">
          <div className="pageslider-intro-container28">
            <video
              ref={videoRef}
              src="/external/Intro4.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-intro-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-intro-summarybutton3 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
              <div className="pageslider-intro-summary3">
                <span className="pageslider-intro-text15">What can we do?</span>
                <ul className="pageslider-intro-ul3 list">
                  <li className="pageslider-intro-li12 list-item">
                    <div className="pageslider-intro-container29">
                      <span className="pageslider-intro-text16">
                        Death from an overdose usually occurs within 1 to 2
                        hours, rather than suddenly.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li13 list-item">
                    <div className="pageslider-intro-container30">
                      <span className="pageslider-intro-text17">
                        If a person’s breathing slows or stops, their heart rate
                        becomes irregular, or they fail to respond, then it is a
                        medical emergency.
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li14 list-item">
                    <div className="pageslider-intro-container31">
                      <span className="pageslider-intro-text18">
                        Action steps must be taken immediately to reverse the
                        overdose.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </div>
                  </li>
                  <li className="pageslider-intro-li15 list-item">
                    <div className="pageslider-intro-container32">
                      <span className="pageslider-intro-text19">
                        <span className="pageslider-intro-text20">
                          This is why we need volunteers like you!
                        </span>
                        <span className="">
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <svg viewBox="0 0 1024 1024" onClick={() => handleButtonClick(5)} className="pageslider-intro-next">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
           )}
          </div>
        </div>
      )}
      {slideNumber === 5 && (
        <div className="pageslider-intro-p5">
          <div className="pageslider-intro-container33">
            <video
              ref={videoRef}
              src="/external/Intro5.mp4"
              poster="/external/vh%20image-1500w.png"
              preload="auto"
              autoPlay
              playsInline
              className="pageslider-intro-video video"
            ></video>
            <button
              type="button"
              onClick={() => setShow_summary(!show_summary)}
              className="pageslider-intro-summarybutton4 button"
            >
            {show_summary ? 'Hide Summary' : 'Show Summary'}
            </button>
            {show_summary && (
              <div className="pageslider-intro-summary4">
                <span className="pageslider-intro-text22">
                  Good Samaritan Law
                </span>
                <span className="pageslider-intro-text23">
                  <span className="">
                    The Good Samaritan Law protects the Citizen Responder when
                    they:
                  </span>
                  <br className="pageslider-intro-text25"></br>
                </span>
                <ul className="pageslider-intro-ul4 list">https://dash.cloudflare.com/93817e0ace787d0f430c9e3c1b9d0b9f/ai
                  <li className="pageslider-intro-li16 list-item">
                    <span className="pageslider-intro-text28">
                      Act in good faith
                    </span>
                  </li>
                  <li className="pageslider-intro-li17 list-item">
                    <span className="pageslider-intro-text29">
                      Not display &quot;gross negligence or willful
                      misconduct&quot;
                    </span>
                  </li>
                  <li className="pageslider-intro-li18 list-item">
                    <span className="pageslider-intro-text30">
                      Attempt to summon police and EMS
                    </span>
                  </li>
                  <li className="pageslider-intro-li19 list-item">
                    <span className="pageslider-intro-text31">
                      Cooperate with law enforcement officials, give the name of
                      the person who overdosed, remain on scene until EMS and
                      police arrive
                    </span>
                  </li>
                  <li className="pageslider-intro-li20 list-item">
                    <span className="pageslider-intro-text32">
                      {' '}
                      Civil liability- Citizen Responder is protected in case
                      the person who overdosed dies.
                    </span>
                  </li>
                  <li className="pageslider-intro-li21 list-item">
                    <span className="pageslider-intro-text33">
                      Criminal Liability- Citizen Responder is protected from
                      charges related to drug possession.
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="pageslider-intro-container_nav">
            <svg viewBox="0 0 1024 1024" onClick={handleReplay} className="pageslider-intro-replay">
              <path
                d="M512 214q142 0 242 100t100 240q0 142-101 242t-241 100-241-100-101-242h86q0 106 75 181t181 75 181-75 75-181-75-181-181-75v172l-214-214 214-214v172z"
                className=""
              ></path>
            </svg>
            {isPlaying ? (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-pause">
              <path
                d="M598 214h170v596h-170v-596zM256 810v-596h170v596h-170z"
                className=""
              ></path>
            </svg>
          ) : (
            <svg viewBox="0 0 1024 1024" onClick={handlePlayPause} className="pageslider-intro-play">
              <path d="M342 214l468 298-468 298v-596z" className=""></path>
            </svg>
          )}
           {videoEnded && (
            <Link to={`/signs?username=${original_username}`} className="pageslider-intro-navlink" onClick={() => { calculateTimeSpent(); increaseTrainingPercentage(14); }}>            
            <svg viewBox="0 0 1024 1024" 
            className="pageslider-intro-next4">
              <path
                d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
                className=""
              ></path>
              <path
                d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
                className=""
              ></path>
            </svg>
          </Link>
          )}
          </div>
          {/* <svg viewBox="0 0 1024 1024" className="pageslider-intro-next4">
            <path
              d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"
              className=""
            ></path>
            <path
              d="M354.744 706.744l90.512 90.512 285.254-285.256-285.256-285.254-90.508 90.508 194.744 194.746z"
              className=""
            ></path>
          </svg> */}
        </div>
      )}
    </div>
  )
}

PagesliderIntro.defaultProps = {
  rootClassName: '',
}

PagesliderIntro.propTypes = {
  rootClassName: PropTypes.string,
}

export default PagesliderIntro
