import React,{ createContext, useContext, useEffect,useState,useRef }  from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom';
import './style.css'
import NotFound from './views/not-found'
import Test from './views/test'
import Home from './views/home'
import Introduction from './views/introduction'
import Signs from './views/signs'
import DownloadData from './views/downloaddata'
import HowNARCAN from './views/how-narcan'
import WorkNARCAN from './views/work-narcan'
import CPR from './views/cpr'
import Pulsepoint from './views/pulsepoint'
import Conclusion from './views/conclusion'
import Stigma from './views/stigma'
import Safety from './views/safety'
import ORRSModules from './views/orrsmodules'
const UserContext = createContext();


const addusername = async (data) => {
  const resp = await fetch(
    `https://orrsdbworker.mohanzalake.workers.dev/api/save_username?username=${data}`
  );
  const body = await resp.text();
  return body;
}

const adduserdata = async (username,field, data) => {
  try {
    const resp = await fetch(
      `https://orrsdbworker.mohanzalake.workers.dev/api/adduserdata?username=${username}&field=${field}&data=${data}`,
      // { mode: 'no-cors' }  // Add this line
      );
    if (!resp.ok) {
      // throw new Error(`HTTP error! status: ${resp.status}`);
      console.log('Fetch failed:', resp.status);

    }
  } catch (error) {
    console.log('Fetch failed');
  }
}

const App = () => {
  const params = new URLSearchParams(window.location.search);
  var [username, setUsername] = useState(params.get('username') || "default_username");
  // var condition = params.get('condition') || "default_condition";
  const original_username = useRef(username).current;
  const url = new URL(window.location.href);
  const [startTimeOrig, setStartTimeOrig] = useState(Date.now());
  const [trainingPercentage, setTrainingPercentage] = useState(0);
  const [hasRedirected, setHasRedirected] = useState(false);
  // console.log("username:"+username);
  // console.log("condition:"+condition);
  // adduserdata(username,"Training_link",encodeURIComponent(String(url.origin)+"?username="+original_username));
  // adduserdata(username,"Condition",condition);

  useEffect(() => {
    if (!hasRedirected) {
    const lastModule = localStorage.getItem('lastModule');
    if (lastModule && !hasRedirected && location.pathname !== lastModule) {
      setHasRedirected(true);
      window.location.href = `${lastModule}?username=${original_username}`;
    }
    else {
    var newUsername = addusername(username)
      .then((newUsername) => {
        console.log("username:"+newUsername);
        setUsername(newUsername);
        // adduserdata(original_username,"Training_link",encodeURIComponent(String(url.origin)+"?username="+original_username));
        var startDate = new Date().toLocaleDateString("en-US", {timeZone: "America/New_York"});
        var startTime = new Date().toLocaleTimeString("en-US", {timeZone: "America/New_York"});
        if(newUsername === username+"_1"){
          adduserdata(original_username,"StartDateEastern",startDate);
          adduserdata(original_username,"StartTimeEastern",startTime);
          adduserdata(original_username,"TrainingStatus","Incomplete");
          adduserdata(original_username,"TrainingPercentage",0);

        }
        adduserdata(newUsername,"StartDateEastern",startDate);
        adduserdata(newUsername,"StartTimeEastern",startTime);
        // adduserdata(newUsername,"Training_link",encodeURIComponent(String(url.origin)+"?username="+original_username));
        // adduserdata(newUsername,"Condition",condition);
        window.addEventListener('beforeunload', (event) => handleBeforeUnload(event, newUsername));
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
      })
      .catch((error) => console.error('Error updating username:', error));
    }

  }
}, [addusername,hasRedirected]);

  const handleBeforeUnload = async (event,newUsername) => {
    // Calculate the total time spent on the website
    // const endTime = Date.now();
    // const totalTimeSpent = endTime - startTimeOrig;
    // // Add the total time spent to the user data
    // var PageCloseDate = new Date().toLocaleDateString("en-US", {timeZone: "America/New_York"});
    // var PageCloseTime = new Date().toLocaleTimeString("en-US", {timeZone: "America/New_York"});
    // adduserdata(newUsername,"PageCloseDateEastern",PageCloseDate);
    // adduserdata(newUsername,"PageCloseTimeEastern",PageCloseTime);
    // await adduserdata(newUsername, 'TotalTimeSpentMilliseconds', totalTimeSpent);
  };
  
  const increaseTrainingPercentage = (increaseAmount) => {
    adduserdata(original_username,"TrainingPercentage",increaseAmount);
    if(increaseAmount === 100){
      adduserdata(original_username,"TrainingStatus","Complete");
    }
  }


  return (
    <UserContext.Provider value={{username,original_username}}>
    <Router>
      <div>
        {/* <Route component={NotFound} path="**" /> */}
        {/* <Route component={Test} exact path="/test" /> */}
        <Route exact path="/" render={(props) => <Home {...props} originalUsername={original_username} />} />
        <Route component={() => <Introduction increaseTrainingPercentage={increaseTrainingPercentage} />}  exact path="/introduction" />
        <Route component={() => <Signs increaseTrainingPercentage={increaseTrainingPercentage} />} exact path="/signs" />
        <Route component={() => <Safety increaseTrainingPercentage={increaseTrainingPercentage} />} exact path="/safety" />
        <Route component={() => <HowNARCAN increaseTrainingPercentage={increaseTrainingPercentage} />} exact path="/howNARCAN" />
        <Route component={WorkNARCAN}  exact path="/workNARCAN" />
        <Route component={() => <CPR increaseTrainingPercentage={increaseTrainingPercentage} />} exact path="/cpr" />
        <Route component={() => <Pulsepoint increaseTrainingPercentage={increaseTrainingPercentage} />} exact path="/pulsepoint" />
        <Route component={Stigma}  exact path="/stigma" />
        <Route component={() => <Conclusion increaseTrainingPercentage={increaseTrainingPercentage} />}  exact path="/conclusion" />
        <Route component={DownloadData} exact path="/downloaddata" />
        <Route component={ORRSModules} exact path="/orrsmodules" />
      </div>
    </Router>
    </UserContext.Provider>
  )
}

// const username = window.location.pathname.split('/')[1]
// addUserdata("test");
export const useUser = () => useContext(UserContext);
ReactDOM.render(<App />, document.getElementById('app'))
